@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component {
    &-text-image-50-50 {
        margin-bottom: -200px !important;

        .wrapper{
            @include grid(16, var(--gutter-width));

            h2 {
                position: relative;
                z-index:2;
                @include col(9);
                @include col-start(5);
            }

            .suptitle{
                position: relative;
                z-index:2;
                margin-top: 15px;
                @include col(4);
                @include col-start(1);
            }

            .description{
                @include col(6);
                @include col-start(10);

                br {
                    border-bottom: 5px solid transparent; // Works in Chrome/Safari
                    content: "";
                    display: block;
                }

                @-moz-document url-prefix() {
                    br {
                      margin-bottom: 5px; // As 'border-bottom' doesn't work in firefox.
                    }
                }

                .author-name {
                    font-weight: bold;
                    margin: 40px 0 5px;
                }

                .author-title {
                    margin-top: 0;
                }


            }

            .img-wrapper{
                position: relative;
                top: 60px;
                @include col(8);
                @include col-start(1);
                border-radius: 20px;
                overflow: hidden;
            }
            img{
                position: relative;
                top:-100px;
                width: 100%;
                scale: 1.15;
                height:auto;
                object-fit: cover;
            }
        }
        @media only screen and (max-width: $md-max) {
            margin-bottom: -100px!important;
        }

        @media only screen and (max-width: $sm-max) {

            margin-bottom: 0px!important;

            .wrapper{

                h2 {
                    @include col(16);
                    @include col-start(1);
                }

                .suptitle{
                    margin-top: 0;
                    @include col(16);
                    @include col-start(1);
                }

                .description{
                    @include col(16);
                    @include col-start(1);
                }

                .img-wrapper{
                    top: 0px;
                    @include col(16);
                    @include col-start(1);
                    border-radius: 6px;

                    img{
                        position: relative;
                        top:-50px;
                    }
                }
            }
        }
    }
}
