@use "../../config" as *;
@use "../../mixins" as *;

.back-btn{
    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    gap: 0px;
    flex-shrink: 0;
    border: none;
    border-radius: 100px;
    background: transparent;
    color: $black;
    padding: 12px 3px;
    font-size: vw(14px, false, 14px);
    line-height: normal;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
        text-decoration: none;
        padding-left: vw(25px, false, 25px);

        span[class^="icon-"].icon-arrow{
            opacity:1;
            left: 4px;
        }
    }

    span[class^="icon-"].icon-arrow{
        position: absolute;
        top: 50%;
        left: 12px;
        width: 12px;
        transform: translate(-0%, -50%) rotate(180deg);
        opacity:0;
        transition: opacity 0.15s ease, left 0.15s ease;
    }

    @media only screen and (max-width: $sm-max) {
        text-decoration: none;
        padding-left: vw(25px, false, 25px);

        span[class^="icon-"].icon-arrow{
            opacity:1;
            left: 4px;
        }
    }

    span{
        position: relative;
        z-index:1;
    }
}
