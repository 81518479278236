@use "../config" as *;
@use "../mixins" as *;
@use "../functions" as *;

.toolkit {
  section {
    margin-bottom: rem(48px);

    &:first-child {
      margin-top: rem(96px);
    }

    h2.tk-title {
      @include steradian();
  
      border-bottom: 1px solid black;
      border-top: 1px solid black;
      font-size: vw(20px, false, 12px);
      font-weight: 900;
      line-height:1.4;
      margin: 20px 0;
      padding: 20px 0;
    }

    h3.tk-title {
      margin: rem(24px) 0 rem(12px);
      font-size: vw(20px, false, 12px);
      font-style: italic;
    }
  }

}