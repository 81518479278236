@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.pagebuilder-component-projects_carousel {
    & + .pagebuilder-component-projects_carousel {
        margin-top: vw(150px);
    }

    .centered-content {
        @media only screen and (max-width: $xs-max) {
            width: 100%;
        }
    }

    .wrapper {
        @include grid(16, var(--gutter-width));

        &:target {
            scroll-margin-top: 100px;
        }

        @media only screen and (max-width: $xs-max) {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .title,
            .description {
                margin-left: auto;
                margin-right: auto;
                width: calc(100% - 8px * 4);
            }

            .content {
                width: 100%;
                overflow: hidden;
            }
        }

        .title {
            @include col(3);
            @include col-start(1);
        }

        .content {
            @include col(12);
            @include col-start(5);
            @include grid(12, var(--gutter-width));

            .description {
                @include col(8);
                @include col-start(1);

                margin-bottom: 50px;

                @media only screen and (max-width: $xs-max) {
                    @include col(12);
                }
            }
        }

        .gallery {
            overflow: hidden;
            position: relative;
            @include grid(12, var(--gutter-width));

            @media only screen and (max-width: $xs-max) {
                display: block;
                overflow-x: scroll;
                padding-bottom: 8px;
                scroll-snap-type: x mandatory;
            }

            .controls {
                position: absolute;
                bottom: -80px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media only screen and (max-width: $xs-max) {
                    display: none;
                }

                .slides-count {
                    font-size: 16px;
                }
            }

            .images-ctn {
                position: relative;

                @media only screen and (max-width: $xs-max) {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: stretch;
                    width: max-content;
                    gap: 7px;
                    padding-left: 16px;
                    padding-right: 16px;
                }

                &.large {
                    @include col(8);
                    @include col-start(1);
                    aspect-ratio: 665 / 458;

                    @media only screen and (max-width: $xs-max) {
                        aspect-ratio: auto;
                    }
                }

                &.small {
                    @include col(4);
                    @include col-start(9);
                    aspect-ratio: 311 / 214;

                    .hover-overlay {
                        padding: vw(20px) 20px;

                        @media only screen and (max-width: $sm-max) {
                            padding: 10px;
                        }
                    }

                    @media only screen and (max-width: $xs-max) {
                        display: none;
                    }
                }

                &.single {
                    width: 100%;
                    margin-bottom: 0;
                }

                >a {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    border-radius:0;
                    transition: border-radius 0.3s ease;
                    animation: hide 0.35s cubic-bezier(0.46, 0.03, 0.52, 0.96) both;

                    img{
                        position: absolute;
                        top: 0;
                        object-fit: cover;
                        height: 100%;
                        width: auto;
                    }

                    @media only screen and (max-width: $xs-max) {
                        position: relative;
                        animation: none;
                        scroll-snap-align: center;
                        max-height: 240px;

                        img{
                            position: relative;
                            max-height: 240px;
                        }
                    }

                    &.active {
                        animation: show 0.35s 0.45s cubic-bezier(0.46, 0.03, 0.52, 0.96) both;
                    }

                    &:hover,
                    &:focus {
                        border-radius: 10px;
                        .hover-overlay {
                            opacity: 1;
                        }
                    }
                }

                .hover-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    max-width: 100vw;
                    height: 100%;
                    background-color: $blue;
                    padding: vw(40px) 35px;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    transition: opacity 0.2s linear;

                    @media only screen and (max-width: $sm-max) {
                        padding: 20px;
                    }

                    @media only screen and (max-width: $xs-max) {
                        display: none;
                    }

                    .project-title {
                        &.like-h3 {
                            font-size: vw(80px, false, 37px);
                            line-height: 0.95;
                        }

                        &.like-h4 {
                            font-size: vw(37px, false, 24px);
                        }
                    }
                }
            }
        }
    }
}

.lg-container {
    .lg-prev:after {
        content: '';
    }
    .lg-next:before {
        content: '';
    }
}

@keyframes show {
    0% {
        clip-path: inset(0 0 0 100%);
    }
    100% {
        clip-path: inset(0 0 0 0);
    }
}
@keyframes hide {
    0% {
        clip-path: inset(0 0 0 0);
    }
    100% {
        clip-path: inset(0 100% 0 0);
    }
}
