@use '../../config' as *;
@use '../../mixins' as *;
@import '../../functions';

header {
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0;
    pointer-events: none;

    @media screen and (max-width: $xs-max) {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-color: $red;
            opacity: 0;
            pointer-events: none;
            transform: scale(0.9);
            transition: opacity 0.2s linear, transform 0.2s $ease-in-cubic;
        }
    }

    .centered-content {
        padding: 25px 0;
        pointer-events: none;

        > div {
            position: relative;
            pointer-events: none;
            display: flex;
            justify-content: space-between;
            overflow: hidden;

            #main-logo {
                pointer-events: auto;
                display: flex;
                flex-wrap: nowrap;
                gap: 2px;
                position: relative;
                height: 50px;

                & > div {
                    position: relative;
                    border: 1px solid black;
                    background-color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-shrink: 0;
                    height: 100%;
                    overflow: hidden;
                    transition: all 0.3s ease;

                    span {
                        margin-left: 15px;
                    }

                    > span:last-child {
                        transform: translateY(-50%) translateX(-60px);
                        position: absolute;
                        top: 50%;
                    }

                    > span:first-child {
                        transform: translateX(0px);
                    }

                    > span {
                        transition: transform 0.3s ease;
                    }
                }

                & > div:nth-child(1),
                & > div:nth-child(3) {
                    border-radius: 100px;
                    aspect-ratio: 480/210;

                    span {
                        margin-left: 17px;
                    }

                    .icon-logo-quatre svg {
                        width: 80.5px;
                    }

                    .icon-logo-4 svg {
                        width: 13px;
                    }
                }

                & > div:nth-child(2) {
                    border-radius: 6px;
                    aspect-ratio: 382/210;

                    .icon-logo-cent svg {
                        width: 62.2px;
                    }

                    .icon-logo-c svg {
                        width: 18px;
                    }
                }

                @media screen and (max-width: $sm-max) {
                    height: 45px;

                    & > div:nth-child(1),
                    & > div:nth-child(3) {
                        span:nth-child(1) {
                            margin-left: 11px;
                        }

                        span:nth-child(2) {
                            margin-left: 14px;
                        }
                    }

                    & > div:nth-child(2) {
                        span:nth-child(1) {
                            margin-left: 9px;
                        }
                        span:nth-child(2) {
                            margin-left: 12px;
                        }
                    }
                }

                @media screen and (max-width: 840px) {
                    & > div {
                        span:first-child {
                            display: none;
                        }
                        span:last-child {
                            display: block;
                            transform: translateY(-50%) translateX(0);
                        }
                    }

                    & > div:nth-child(1),
                    & > div:nth-child(3) {
                        border-radius: 130px;
                        aspect-ratio: 1/1;

                        span {
                            margin-left: 15px;
                        }
                    }

                    & > div:nth-child(2) {
                        aspect-ratio: 1/1;

                        span {
                            margin-left: 13px;
                        }
                    }
                }
            }

            .nav {
                pointer-events: auto;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                gap: 5px;

                .burger {
                    position: relative;
                    background: $white;
                    cursor: pointer;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 1px solid $black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 10;
                    transition: transform 0.2s linear;

                    @media screen and (max-width: $sm-max) {
                        width: 45px;
                        height: 45px;
                    }

                    svg {
                        transition: height 0.2s ease;

                        line {
                            transition: width 0.2s ease, transform 0.2s ease;
                        }
                    }
                }

                > ul {
                    position: absolute;
                    list-style: none;
                    margin: 0;
                    display: flex;
                    gap: 5px;
                    transition: opacity 0.3s ease, transform 0.3s ease, padding 0.3s ease;
                    transition-delay: 0.1s;

                    @media screen and (max-width: $xs-max) {
                        display: block;
                        position: fixed;
                        top: max(95px, 17dvh);
                        left: 0;
                        width: 100%;
                        height: calc(100dvh - max(95px, 17dvh));
                        overflow-y: scroll;
                        overflow-x: hidden;
                        animation: none;
                        opacity: 0;
                        transition: opacity 0.1s $ease-in-cubic;
                        transition-delay: 0s;
                        z-index: 9;
                        pointer-events: none;

                        > li:not(:first-child):not(.mobile-menu-extra) {
                            margin-top: 20px;
                        }

                        li:not(.mobile-menu-extra) {
                            opacity: 0;
                            transform: translateX(50px);
                        }

                        a span {
                            @include freigeist-light();
                            font-size: vw(58px, true, 40px, 58px);
                            font-weight: 250;
                            letter-spacing: 0.58px;
                        }
                    }
                }

                .mobile-menu-extra {
                    display: none;

                    @media screen and (max-width: $xs-max) {
                        margin: 60px 20px 30px;

                        ul {
                            list-style-type: none;
                            margin: 0;
                            padding: 0;

                            &:not(:first-child) {
                                margin-top: 30px;
                            }

                            &:not(.lang-switcher) {
                                li:first-child {
                                    margin-bottom: 10px;
                                }

                                a,
                                button span {
                                    @include steradian();
                                    font-size: vw(18px, true, 14px, 18px);
                                    line-height: 1.4;
                                    font-weight: 400;
                                }
                            }

                            &.lang-switcher {
                                li {
                                    text-align: right;

                                    a {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // Desktop behavior
    @media screen and (min-width: $sm-min) {
        &:not(.scrolled) .centered-content > div {
            .burger {
                transform: scale(0);
                pointer-events: none;
            }
        }

        &.scrolled .centered-content > div {
            #main-logo {
                div {
                    span:first-child {
                        transform: translateX(80px);
                    }
                    span:last-child {
                        transform: translateY(-50%) translateX(0px);
                    }
                }

                div:nth-child(1),
                div:nth-child(3) {
                    border-radius: 130px;
                    aspect-ratio: 1/1;
                }

                div:nth-child(2) {
                    aspect-ratio: 1/1;
                }
            }

            ul {
                transition-delay: 0.1s;
                transform: translateX(15%);
                opacity: 0;
                padding-right: 10px;

                li{
                    pointer-events: none;
                }
            }

            .burger {
                transform: scale(1);
                pointer-events: all;
            }
        }

        // Show nav on desktop
        &.nav-visible .centered-content > div {
            .burger {
                svg {
                    line:nth-child(1),
                    line:nth-child(3) {
                        width: 5px;
                        transform: translateX(5px);
                    }
                }
            }

            ul {
                transform: translateX(-50px);
                opacity: 1;

                li{
                    pointer-events: revert;
                }
            }
        }
    }

    // Show mobile menu on mobile
    @media screen and (max-width: $xs-max) {
        &.nav-opened {
            &::before {
                opacity: 1;
                transform: scale(1);
            }

            & .centered-content > div {
                .burger {
                    svg {
                        height: 12px;

                        line:nth-child(1) {
                            transform: translate(-4px, 3px) rotate(-45deg);
                        }

                        line:nth-child(3) {
                            transform: translate(2px, -1px) rotate(45deg);
                        }

                        line:nth-child(2) {
                            opacity: 0;
                        }
                    }
                }

                .nav > ul {
                    padding: 0;
                    opacity: 1;
                    pointer-events: all;
                    transition-delay: 0.2s;
                }

                .mobile-menu-extra {
                    display: block;
                }
            }
        }
    }
}
