@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component {
    &-image-image {
        margin-bottom: -150px !important;

        .wrapper {
            @include grid(16, var(--gutter-width));

            .img-wrapper {
                @include col(8);

                border-radius: 13px;
                overflow: hidden;
                position: relative;
                top: 60px;

                img {
                    height:auto;
                    object-fit: cover;
                    position: relative;
                    transform: scale(1.15);
                    top:-100px;
                    width: 100%;
                }
            }
        }

        @media only screen and (max-width: $sm-max) {
            margin-bottom: 0px!important;

            .wrapper {
                .img-wrapper {
                    @include col(16);
                    @include col-start(1);

                    border-radius: 6px;
                    top: 50px;

                    img {
                        position: relative;
                        top:-70px;
                    }
                }
            }
        }

        @media only screen and (max-width: $xs-max) {
            .wrapper {
                .img-wrapper {
                    top: 0px;

                    img {
                        top:0px;
                    }
                }
            }
        }
    }
}
