@use '../../config' as *;
@use '../../mixins' as *;
@use '../../functions' as *;

span[class^='icon-'] {
    display: inline-block;

    svg {
        position:relative;
        z-index:1;
        box-sizing: content-box;
        display: block;
        width: 100%;
        margin: 0;
        transform: translateZ(0);
        box-shadow:none;
    }

    &.animated-homepage {
        width: 442px;
    }

    &.icon-arrow {
        width: 24px;
    }

    &.icon-copy {
        width: 20px;
    }

    &.icon-logo-4 {
        width: 30px;
    }

    &.icon-logo-c {
        width: 42px;
    }

    &.icon-external {
        width: 12px;
    }

    &.icon-external-primary-btn {
        width: 16px;
    }

    &.icon-burger {
        width: 12px;
    }

    &.icon-computer {
        width: 421px;
    }
}

.contact-svg {
    pointer-events: none;

    &.talk-with-us {
        width: vw(470px, false, 390px);
    }

    &.coffee {
        width: vw(400px, false, 400px);
    }

    &.jobs {
        width: vw(340px, false, 340px);
    }
}

.icon-animated-404 {
    position: absolute;
    top: max(29vh, 300px) !important;
    right: 10vw !important;
    transform: none !important;
    left: auto;
    z-index: 0;
    width: vw(250px, false, 150px);
}

.animated-svg {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100vw);
    z-index: 10;

    &.icon-animated-maintenance {
        position: absolute;
        top: 44vh !important;
        right: 6vw !important;
        transform: none !important;
        left: auto;
        z-index: 0;
        width: vw(450px, false, 450px);

        &.error-500{
            top: 28vh !important;
        }
    }

    &.icon-animated-canon {
        width: 700px;
    }

    &.icon-animated-bottle {
        left:auto;
        transform: none;
        width: vw(170px, false, 100px);
    }

    &.icon-animated-homepage {
        width: vw(400px, false, 400px);
    }

    &.icon-animated-project {
        width: vw(520px, false, 400px);
    }

    &.icon-animated-expertise {
        width: vw(650px, false, 650px);
    }

    &.icon-animated-agence {
        width: vw(470px, false, 470px);
    }

    &.icon-animated-contact {
        width: vw(418px, false, 398px);
    }
    &.icon-animated-carriere {
        width: vw(750px, false, 650px);
        left:auto;
        right: 55%;
        top: -10%;
    }
}

svg.illustration,
.illustration {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100vw);
    z-index: 10;

    &.illustration-eye {
        width: vw(157px, false, 95px);
    }

    &.illustration-smiley,
    &.illustration-smiley-right {
        //the svg could not be exporter properly because of a rotation in one of the part thus expanding the viewbox and making the svg smaller than intended.
        //so we compensate with a large size and move it back with negative margin to recenter it
        width: vw(106px, false, 65px);
        margin-top: -1vw;
        margin-left:-1vw;
    }

    &.illustration-smiley-happy,
    &.illustration-smiley-surprised {
        width: vw(86px, false, 58px);
    }

    &.illustration-light {
        width: vw(169px, false, 78px);
    }

    &.illustration-star,
    &.illustration-star-right {
        width: vw(86px, false, 60px);
    }

    &.illustration-hand {
        width: vw(150px, false, 150px);
    }

    &.illustration-phone {
        width: vw(165px, false, 165px);
    }

    &.illustration-flash,
    &.illustration-flash-right {
        width: vw(86px, false, 60px);
    }
}

@media only screen and (max-width: $sm-max) {
    .animated-svg {
        &.icon-animated-maintenance {
            top: 51vh !important;
            right: auto;
            left: 4vw !important;
            width: vw(350px, false, 350px);
        }

        &.icon-animated-homepage {
            width: vw(200px, false, 200px);
        }

        &.icon-animated-expertise {
            width: vw(350px, false, 350px);
        }

        &.icon-animated-agence {
            width: vw(350px, false, 350px);
        }

        &.icon-animated-contact {
            width: vw(380px, false, 380px);
        }
    }

    .contact-svg {
        &.talk-with-us {
            width: vw(490px, false, 340px);
        }

        &.jobs {
            width: vw(340px, false, 270px);
        }

        &.coffee {
            width: vw(340px, false, 270px);
        }
    }
}

@media only screen and (max-width: $xs-max) {
    span[class^='icon-'] {
        &.animated-svg {
            display: none;
        }

        &.icon-animated-404 {
            display: none;
        }
    }

    svg.illustration,
    .illustration {
        .illustration-eye {
            width: 95px;
        }

        &.illustration-smiley,
        &.illustration-smiley-right,
        &.illustration-smiley-happy,
        &.illustration-smiley-surprised {
            width: 58px;
        }

        &.illustration-light {
            width: 78px;
        }

        &.illustration-star,
        &.illustration-star-right {
            width: 60px;
        }

        &.illustration-hand {
            display: none;
        }

        &.illustration-phone {
            width: 165px;
        }

        &.illustration-flash,
        &.illustration-flash-right {
            width: 60px;
        }
    }
}
