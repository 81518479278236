@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.pagebuilder-component {
    &-wysiwyg {
        figure.image{
            margin: 0;
            border-radius: 20px;
            overflow: hidden;
            line-height:0;

            img{
                width: 100%;
                height: auto;

            }
        }
        figure.media {
            margin: 0;

            & > div > div {
                padding-bottom: 0 !important;
                height: auto !important;
                max-height: 90vh;
                aspect-ratio: 16/9;
                margin: 50px auto;
            }
        }
    }
}
