@use '../../config' as *;
@use '../../mixins' as *;

.arrow-controls {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    width: 50px;
    height: 50px;
    padding: 0;
    align-items: center;
    justify-content: center;
    gap: 0px;
    flex-shrink: 0;
    border: 1px solid $black;
    border-radius: 100px;
    color: $black;
    background: $white;
    cursor: pointer;
    transition: opacity 0.3s ease;

    svg {
        transform-origin: center;
    }

    &.previous {
        svg {
            transform: rotate(180deg);
        }
    }

    &:disabled {
        opacity: 0;
        cursor: default;
    }

    &:not(:disabled):hover {
        text-decoration: none;

        svg {
            animation: arrowToRight 2.5s cubic-bezier(0.77, 0, 0.175, 1)
                forwards infinite;
            animation-delay: -1.7s;
        }

        &.previous svg {
            animation-name: arrowToLeft;
        }
    }
}

@keyframes arrowToRight {
    0% {
        transform: translate3d(-150%, 0, 0) rotateX(90deg) scale(0.5);
    }
    30% {
        transform: translate3d(0, 0, 0) rotateX(0deg) scale(1);
    }
    70% {
        transform: translate3d(0, 0, 0) rotateX(0deg) scale(1);
    }
    95% {
        transform: translate3d(150%, 0, 0) rotateX(-90deg) scale(0.5);
    }
    100% {
        transform: translate3d(150%, 0, 0) rotateX(-90deg) scale(0.5);
    }
}
@keyframes arrowToLeft {
    0% {
        transform: translate3d(150%, 0, 0) rotateX(90deg) scale(0.5)
            rotate(180deg);
    }
    30% {
        transform: translate3d(0, 0, 0) rotateX(0deg) scale(1) rotate(180deg);
    }
    70% {
        transform: translate3d(0, 0, 0) rotateX(0deg) scale(1) rotate(180deg);
    }
    95% {
        transform: translate3d(-150%, 0, 0) rotateX(-90deg) scale(0.5)
            rotate(180deg);
    }
    100% {
        transform: translate3d(-150%, 0, 0) rotateX(-90deg) scale(0.5)
            rotate(180deg);
    }
}
