@use '../../config' as *;
@use '../../mixins' as *;

.back-to-parent-page {


    .centered-content {
        padding-top: 40px;
        border-top: 1px solid $black;

        a {
            @include col(3);
            @include col-start(1);
        }

        .label-wrapper{
            @include col(11);
            @include col-start(5);
            margin-top: 0;
            padding-top: 8px;
        }

    }

    @media only screen and (max-width: $xs-max) {
        .centered-content {
            a {
                @include col(16);
                @include col-start(1);
            }

            .label-wrapper {
                @include col(16);
                @include col-start(1);
                margin-top: 8px;
                padding-top: 0;
            }
        }
    }
}
