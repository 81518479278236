@mixin steradian($font-weight: normal){
    font-family: 'Steradian-Rg', Arial, helvetica, sans-serif;
    font-weight: $font-weight;
}

@mixin freigeist-light($font-weight: normal){
    font-family: 'FreigeistCon-Light', Arial, helvetica, sans-serif;
    font-weight: $font-weight;
}

