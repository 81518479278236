@use "../../config" as *;
@use "../../mixins" as *;

.external-link{
    display:inline-block;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:hover{
        text-decoration: none;

        .icon-external{
            opacity:1;
            right:-18px;
            transform: rotate(0deg);
        }

        &::before {
            transform: scaleX(1);
        }
    }

    .icon-external{
        position: absolute;
        opacity:0;
        right:-15px;
        top: 4px;
        transform: rotate(12deg);
        transition: all 0.3s ease;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $black;
        transition: transform 0.2s ease;
        transform: scaleX(0);
        transform-origin: left;
    }

    @media only screen and (max-width: $sm-max) {
        .icon-external{
            opacity:1;
            right: -18px;
            transform: rotate(0deg);
        }
    }
}


