@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.pagebuilder-component {
    &-text_3cols {

        &.legal{
            .wysiwyg-wrapper{
                *{
                    font-size: vw(14px, false, 12px, 14px);
                }

            }
        }

        .wrapper{
            @include grid(16, var(--gutter-width));
            @include col(16);

            h5{
                @include col(3);
                @include col-start(1);
            }

            .wysiwyg-wrapper{
                @include col(8);
                @include col-start(5);

                figure.image{
                    margin: 0;
                    border-radius: 20px;
                    overflow: hidden;
                    line-height:0;

                    img{
                        width: 100%;
                        height: auto;

                    }
                }

                ul,ol{
                    padding-left:0;
                    list-style: none;

                    &:first-child {
                        margin-top: 0;
                    }

                    li{
                        display:flex;
                        font-size: vw(20px, false, 12px, 20px);

                        @media only screen and (max-width: $xs-max) {
                            font-size: vw(18px, true, 14px, 18px);
                        }

                        &:before{
                            content: '\2014';
                            padding-right: 15px;
                        }
                    }
                }
                >*:last-child{
                    margin-bottom:0;
                }
            }

            figure.media {
                margin: 0;

                & > div > div {
                    padding-bottom: 0 !important;
                    height: auto !important;
                    max-height: 90vh;
                    aspect-ratio: 16/9;
                    margin: 50px auto;
                }
            }

            >ul{
                @include col-start(14);
                @include col(3);
                list-style: none;
                margin-top: 0;
                margin-bottom: 23px;
                font-size: vw(14px, false, 14px);
                padding-left:0;

                li{
                    margin-bottom: 20px;
                }
            }

            @media only screen and (max-width: $sm-max) {
                h5{
                    @include col(11);
                    @include col-start(1);
                    margin-bottom: 22px;
                }

                .wysiwyg-wrapper {
                    @include col(11);
                    @include col-start(1);
                }

                >ul{
                    @include col-start(13);
                    @include col(4);

                    li{
                        margin-bottom: 14px;
                    }
                }
            }

            @media only screen and (max-width: $xs-max) {
                h5{
                    @include col(16);
                    @include col-start(1);
                }

                .wysiwyg-wrapper {
                    @include col(16);
                    @include col-start(1);
                }

                >ul{
                    margin-top: 34px;
                    @include col-start(1);
                    @include col(16);
                }
            }
        }
    }
}
