@use '../../config' as *;
@use '../../mixins' as *;
@import '../../functions';

.hero {
    /* TODO: à remplacer par 100svh quand le bug sur Safari sera résolu */
    min-height: min(100vh, 1200px);
    padding: rem(190px) 0 rem(100px);
    margin-bottom: rem(200px);
    box-sizing: border-box;
    background: $white;
    border-radius: 0;
    transform: scale(1);
    transition: border-radius 0.6s ease, transform 0.6s ease;

    &.no-margin {
        margin-bottom: rem(0px);
    }

    &.empty-page {
        margin-bottom: 40vh !important;
    }

    &.page-404.with-list {
        @media screen and (max-width: $xs-max) {
            .illustration-star {
                display: none;
            }
        }
        .wrapper {
            h1 {
                @include col(16);
            }

            p {
                @include col-start(4);

                @media screen and (max-width: $sm-max) {
                    @include col-start(9);
                }

                @media screen and (max-width: $xs-max) {
                    @include col-start(1);
                }
            }

            .list-wrapper {
                @include col-start(8);

                @media screen and (max-width: $sm-max) {
                    @include col-start(8);
                }

                @media screen and (max-width: $xs-max) {
                    @include col-start(1);
                }
            }
        }
    }

    @media screen and (max-width: $sm-max) {
        min-height: 70vh;
    }

    @media screen and (max-width: $xs-max) {
        min-height: 0;
        padding-top: 150px;
        margin-bottom: 150px;
    }

    &.for-article {
        h1{
            margin:0;
        }
    }

    &.subpage {
        height: auto;
        min-height: 0;
        padding-bottom: 0;
        margin-bottom: rem(200px);
        position: relative;
        z-index: 2;

        @media screen and (max-width: $sm-max) {
            .illustration {
                top: auto;
                bottom: 100px;
            }
        }

        @media screen and (max-width: $xs-max) {
            .illustration {
                top: 0;
                bottom: auto;
            }
        }

        &.less-bottom-margin {
            margin-bottom: rem(100px);
        }

        .wrapper {
            >h1, >.infos {
                @include col-start(5);
                @include col(11);
            }

            .back-btn {
                @include col-start(1);
                @include col(4);
            }

            @media screen and (max-width: $sm-max) {
                h1 {
                    @include col-start(1);
                    @include col(16);
                }

                .back-btn {
                    @include col-start(1);
                    @include col(8);
                    margin-bottom: 15px;
                }
            }
        }

        &:after {
            content: ' ';
            position: absolute;
            top: calc(100% + 75px);
            left: 0;
            background: $white;
            height: 100vh;
            width: 100%;
            opacity: 1;
            pointer-events: none;
            animation: fadeOut 0.6s 1.5s ease both;
        }
    }

    &.with-image {
        height: auto;
        min-height: 0;
        padding-bottom: 0;
        margin-bottom: rem(150px);

        @media screen and (max-width: $xs-max) {
            margin-bottom: 100px;
        }

        .primary-btn {
            opacity: 0;
            transform: translateY(30px);
        }

        .hero-image {
            opacity: 0;
            overflow: hidden;
            display: flex;
            align-items: center;

            img {
                object-fit: cover;
                scale: 1.06;
                width: 110%;
                position:relative;
                top:50px;

                @media only screen and (max-width: $sm-max) {
                    top:0px;
                }
            }
        }

        @media only screen and (max-width: $sm-max) {
            margin-bottom: rem(100px);
        }
    }

    &.scrolled {
        border-radius: 20px;
        transform: scale(var(--container-width-percentage));
    }

    &.with-list {
        @media screen and (max-width: $xs-max) {
            min-height: 70vh;
            padding-top: 150px;

            // Disable hover/focus arrow for the hero in mobile (not enough space)
            .primary-btn:hover,
            .primary-btn:focus {
                padding-left: 20px;

                .icon-arrow {
                    opacity: 0;
                }
            }
        }

        .wrapper {
            h1 {
                margin-bottom: 100px;

                @media screen and (max-width: $xs-max) {
                    margin-bottom: 60px;
                }
            }

            p {
                position: relative;
                margin-top: 10px;
                text-align: right;
                @include col(4);
                @include col-start(6);

                @media screen and (max-width: $sm-max) {
                    @include col-start(4);
                    text-align: left;
                    margin-top: 0;
                }

                @media screen and (max-width: $xs-max) {
                    @include col(16);
                    @include col-start(1);
                    margin-bottom: 30px;
                }
            }
        }
    }

    &:not(.with-list):not(.with-image):not(.for-article) {
        display: flex;
        .wrapper {
            h1 {
                margin-bottom: 0;
            }

            p {
                position: absolute;
                align-self: end;
                margin: 0;
                bottom: 0;
            }
        }

        @media screen and (max-width: $xs-max) {
            .wrapper {
                height: auto;

                p {
                    position: relative;
                    bottom: auto;
                }
            }

            &:not(.subpage) h1 {
                margin-bottom: 50px;
            }
        }
    }

    /* Règles pour animations même après transition de page, avec page contact qui est différente */
    h1,
    .title {
        opacity: 0.001;

        div {
            opacity: 0;
            transform: translateY(30px);
        }
    }
    p,
    .wrapper > .back-btn {
        opacity: 0;
    }
    .wrapper > .back-btn {
        position: absolute;
        top: -40px;
        left: 6px;
    }

    .tertiary-btn {
        margin-top: 15px;
    }

    .button-wrapper {
        position: absolute;
        right: 0;

        @media only screen and (max-width: $xs-max) {
            position: relative;
            bottom: 0;
            margin-bottom: 100px;
            display:flex;
            justify-content: flex-end;
        }
    }

    .wrapper {
        @include grid(16, var(--gutter-width));
        position: relative;
        height: 100%;

        h1 {
            @include col(14);
            @include col-start(1);

            @media screen and (max-width: $xs-max) {
                @include col(16);
                margin-bottom: rem(38px);
            }
        }

        p {
            position: relative;
            @include col(4);
            @include col-start(13);

            @media screen and (max-width: $sm-max) {
                @include col(6);
                @include col-start(9);
                margin-top: 0;
            }

            @media screen and (max-width: $xs-max) {
                @include col(16);
                @include col-start(1);
                margin-top: 0;
            }
        }

        .list-wrapper {
            @include col(6);
            @include col-start(10);

            @media screen and (max-width: $sm-max) {
                @include col-start(8);
            }

            @media screen and (max-width: $xs-max) {
                @include col(16);
                @include col-start(1);
                margin-bottom: -180px;
            }

            ul {
                position: absolute;
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: 5px;
                opacity: 0;

                @media screen and (max-width: $xs-max) {
                    position: relative;
                }

                $yInitialPosition: calc(-100vh - 600px);
                li:nth-child(1) {
                    transform: translate3d(0%, $yInitialPosition, 0)
                    rotate(-20deg);
                }
                li:nth-child(2) {
                    transform: translate3d(0%, $yInitialPosition, 0)
                    rotate(18deg);
                }
                li:nth-child(3) {
                    transform: translate3d(0%, $yInitialPosition, 0)
                    rotate(-12deg);
                }
                li:nth-child(4) {
                    transform: translate3d(0%, $yInitialPosition, 0)
                    rotate(-8deg);
                }
                li:nth-child(5) {
                    transform: translate3d(0%, $yInitialPosition, 0)
                    rotate(2deg);
                }
                li:nth-child(6) {
                    transform: translate3d(0%, $yInitialPosition, 0)
                    rotate(-22deg);
                }
            }
        }
    }

    .hero-image {
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
