@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

[class*='pagebuilder-component'] {
    margin: vw(200px) 0;

    &.small-margin {
        margin: vw(100px) 0 vw(178px);
    }

    &.big-margin {
        margin: vw(200px) 0;
    }

    &.simple-page {
        margin: vw(100px) 0;
    }

    @media screen and (max-width: $sm-max) {
        margin: 100px 0;

        &.small-margin {
            margin: 100px 0 130px;
        }

        &.simple-page,
        &.big-margin {
            margin: 100px 0;
        }
    }
}
