@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.pagebuilder-component {
    &-image_video {
        min-height: 80svh;
        transform: scale(1);
        transition: transform 0.6s $ease-out-quint;

        .wrapper {
            height: auto;
            width: 100%;

            img, video {
                border-radius: 0;
                transition: border-radius 0.6s $ease-out-quint;
                width: 100%;
                height: auto;
            }
        }

        &.scrolled {
            transform: scale(var(--container-width-percentage));

            .wrapper {
                img, video {
                    border-radius: 20px;

                    @media only screen and (max-width: $sm-max) {
                        border-radius: 6px;
                    }
                }
            }
        }

        @media only screen and (max-width: $sm-max) {
            min-height: 0;
        }
    }
}
