@use '../../config' as *;
@use '../../mixins' as *;
@import '../../functions';

.copy-link {
    position: relative;
    background: none;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: $black;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    font-size: rem(20);

    .icon-copy,
    .icon-checkmark {
        width: 18px !important;
        height: 18px;
        opacity: 0;
        transition: opacity 0.2s ease;
        position: absolute;
        right: -30px;
        top: 0;
        bottom: 0;
        margin: auto 0;
    }

    .icon-checkmark {
        transform: rotateY(90deg);
    }

    &:hover {
        text-decoration: none;

        .icon-copy,
        .icon-checkmark {
            opacity: 1;
        }
    }

    @media only screen and (max-width: $sm-max) {
        font-size: rem(14);

        span.icon-copy,
        span.icon-checkmark {
            width: 12px !important;
        }
    }
}
