@use "config" as *;
@use "mixins" as *;
@use "functions" as *;

//
// Root styles
// -----------------------------------------------------------------------------

html {
    --column-amount: #{$columns-amount-lg};
    --gutter-width:  #{$gutter-width-lg};

    // permet de fetch la valeur des breakpoints dans le config.js
    --lg-min: #{$lg-min};
    --md-max: #{$md-max};
    --md-min: #{$md-min};
    --sm-max: #{$sm-max}; 
    --sm-min: #{$sm-min};
    --xs-max: #{$xs-max};

    //Retire le smooth scroll lors des transitions de page avec barba JS pour pas qu'onv oit la page scrolltop lentement pendant la transition.
    &.in-transition{
        scroll-behavior: auto;
        overflow: hidden;
    }

    //classe utilisé lors d'ouverture d'overlays pour éviter le scroll sur le body et dans l'overlay en même temps.
    &.no-scroll{
        overflow: hidden;
    }

    &.lenis {
        height: auto;
    }

    @media only screen and (max-width: $md-max) {
        --column-amount: #{$columns-amount-md};
        --gutter-width: #{$gutter-width-md};
    }

    @media only screen and (max-width: $sm-max) {
        --column-amount: #{$columns-amount-sm};
        --gutter-width: #{$gutter-width-sm};
    }

    @media only screen and (max-width: $xs-max) {
        --column-amount: #{$columns-amount-xs};
        --gutter-width: #{$gutter-width-xs};
    }
}

// Lenis (smoothscroll)
.lenis.lenis-smooth {
    scroll-behavior: auto;
}
.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}
.lenis.lenis-stopped {
    overflow: hidden;
}
.lenis.lenis-scrolling iframe {
    pointer-events: none;
}

.custom-cursor {
    position: fixed;
    top: 0;
    left: 0;
    width: 113px;
    height: 113px;
    overflow: hidden;
    pointer-events: none;
    opacity: 0;
    transition: width 0.5s $ease-out-quart, height 0.5s $ease-out-quart;

    circle, path {
        stroke-width: 0.5px;
        transition: all 0.5s $ease-out-quart;
    }

    &--small {
        width: 50px;
        height: 50px;

        circle, path {
            stroke-width: 1px;
        }
    }
}
.hover-custom-cursor,
.hover-custom-cursor * {
    cursor: none;
}

.grecaptcha-badge{
    visibility: hidden;
}

body {
    margin:0;
    @include steradian();

    &.preload *{
        animation-duration: 0s !important;
        -webkit-animation-duration: 0s !important;
        transition:background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
    }

    //For toolkit
    .toolkit{
        .cols{
            @include grid(16, var(--gutter-width));

            .col{
                @include col(1);
                background: pink;
                opacity:0.6;
                height: 80px;
            }
        }
    }

    #site-ctn{
        background: $white;

        &.transitionning{
            width: 100%;
            position: fixed;
            top:0;
            z-index:2;
        }
    }

    .scroll-anim-item-separator {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $black;
    }
}

//
// Heading styles
// -----------------------------------------------------------------------------

h1 {
    @include freigeist-light();
    font-size: vw(120px, false, 80px);
    line-height:1;
    letter-spacing: 0;
    margin-top:0;
}

h2, .like-h2 {
    @include freigeist-light();
    font-size: vw(106px, false, 60px);
    line-height:1;
    letter-spacing: 1.06px;
    margin-top:0;
}

h3, .like-h3 {
    @include freigeist-light();
    font-size: vw(85px, false, 37px);
    line-height:1;
    letter-spacing: .85px;
    margin-top:0;
}

h4, .like-h4 {
    @include freigeist-light();
    font-size: vw(37px, false, 28px);
    line-height:1;
    letter-spacing: 0;
    margin-top:0;
}

h5, .like-h5, .p-title {
    @include steradian();
    font-size: vw(32px, false, 20px);
    line-height:1.1;
    letter-spacing: 0;
    margin-top:0;
}

h6, .like-h6{
    @include steradian();
    font-size: vw(24px, false, 20px);
    line-height:1.1;
    letter-spacing: 0;
    margin-top:0;
    margin-bottom: 30px;
}

@media only screen and (max-width: $xs-max) {
    h1 {
        font-size: vw(58px, true, 40px, 58px);
        letter-spacing: .58px;
    }

    h2, .like-h2 {
        font-size: vw(58px, true, 40px, 58px);
        letter-spacing: .48px;
    }

    h3, .like-h3 {
        font-size: vw(40px, true, 32px, 40px);
    }

    h4, .like-h4 {
        font-size: vw(32px, true, 24px, 32px);
    }

    h5, .like-h5, h6, .p-title {
        font-size: vw(24px, true, 20px, 24px);
    }
}

//
// Text styles
// -----------------------------------------------------------------------------

p {
    @include steradian();
    font-size: vw(20px, false, 12px, 20px);
    line-height:1.4;
    margin: 20px 0;

    &:first-child{
        margin-top:0;
    }

    &:last-child{
        margin-bottom:0;
    }

    &.small{
        font-size: vw(14px, false, 14px);
        line-height:1;
    }
    &.suptitle{
        text-transform: uppercase;
        font-size: vw(14px, false, 14px);
        line-height:1;
    }

    @media only screen and (max-width: $xs-max) {
        font-size: vw(18px, true, 14px, 18px);
    }
}

span{
    @include steradian();
}

//
// Link styles
// -----------------------------------------------------------------------------

a {
    color: $black;

    &:hover, &:focus {
        text-decoration: underline;
    }

    &:hover, &:focus, &:active, &:visited {
        color: $black;
    }

    &:not(.no-underline):not(.tag) {
        text-decoration: none;
        background-image: linear-gradient(currentColor, currentColor);
        background-position: 0% 100%;
        background-repeat: no-repeat;
        background-size: 0% 1px;
        transition: background-size 0.2s ease;

        &:hover, &:focus {
            text-decoration: none;
            background-size: 100% 1px;
        }
    }
}

//
// List styles
// -----------------------------------------------------------------------------

ul {}

ol {}

li {}

//
// Form styles
// -----------------------------------------------------------------------------

input {}

fieldset{
    margin: 0;
    padding: 0;
    border: none;
}

//
// Coloring
// -----------------------------------------------------------------------------
[data-color=red]{
    &.filled, &::before {
        background: $red;
    }
}
[data-color=purple]{
    &.filled, &::before {
        background: $purple;
    }
}
[data-color=orange]{
    &.filled, &::before {
        background: $orange;
    }
}
[data-color=pink]{
    &.filled, &::before {
        background: $pink;
    }
}
[data-color=blue]{
    &.filled, &::before {
        background: $blue;
    }
}

[data-color=yellow]{
    &.filled, &::before {
        background: $yellow;
    }
}
[data-color=gray]{
    &.filled, &::before {
        background: $gray;
    }
}
.filled::before {
    background: $white;
}

//CUSTOM AND TEMPORARY

#awwwards{
    scale:1;
    transition: scale 0.3s ease;
}

#cssda{
    scale:1;
    rotate: 0deg;
    transition: scale 0.3s ease, rotate 0.5s ease;
}

#awwwards:hover{
    scale: 1.1;
}

#cssda:hover{
    scale: 1.1;
    rotate: -10deg; 
}


//hide awwwards badge on mobile
@media only screen and (max-width: $xs-max) {
    #awwwards{
        display:none;
    }

    #cssda{
        display:none;
    }
}