@use "../../config" as *;
@use "../../mixins" as *;

.tertiary-btn{
    display: inline-block;
    border: none;
    background: transparent;
    color: $black;
    font-size: vw(20px, false, 12px, 20px);
    line-height: normal;
    cursor: pointer;
    position: relative;

    @media only screen and (max-width: $xs-max) {
        font-size: vw(18px, true, 14px, 18px);
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }
}
