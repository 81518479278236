@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.contact-hero {
    @media screen and (max-width: $sm-max) {
        .illustration-flash-right {
            display: none;
        }
    }
}
