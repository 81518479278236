@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.team-listing {
    margin-bottom: 40px;

    @media only screen and (max-width: $xs-max) {
        margin-bottom: 100px;
    }

    .list-wrapper-team {
        list-style: none;
        margin: 0;
        padding: 0;

        .list-item {
            &:first-child > .list-item-wrapper {
                padding-top: 0;
            }

            .list-item-wrapper {
                position: relative;
                padding: 20px 0;
                @include grid(16, var(--gutter-width));

                .tag-wrapper {
                    @include col(4);
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;
                }

                .picture {
                    @include col(6);
                    position: relative;

                    img {
                        display: block;
                        object-fit: cover;
                        position: absolute;
                        left: 0;
                        top: 25%;
                        width: 100%;
                        height: auto;
                        aspect-ratio: 492 / 569;
                        transform: translateY(-50%);
                        pointer-events: none;
                        border-radius: 15px;
                        overflow: hidden;
                        z-index: 5;
                        opacity: 0;
                        transition: opacity 0.1s linear;
                    }
                }

                .name {
                    @include col-start(11);
                    @include col(6);
                    margin-bottom: 0;
                }

                @media screen and (max-width: $xs-max) {
                    display: flex;
                    flex-direction: column-reverse;

                    .tag-wrapper {
                        max-width: 60%;
                    }

                    .name {
                        max-width: 60%;
                        margin-bottom: 15px;
                    }

                    .picture {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 32%;
                        height: 100%;

                        img {
                            aspect-ratio: 108 / 126;
                            border-radius: 4px;
                            top: 0;
                            transform: translateY(20px);
                        }
                    }
                }
            }

            &.active {
                position: relative;
                z-index: 5;

                .tag {
                    &::before {
                        top: 0;
                    }
                }

                .picture img {
                    opacity: 1;
                }
            }
        }
    }
}
