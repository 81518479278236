@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component {
    &-text_image {
        .wrapper {
            @include grid(16, var(--gutter-width));

            .content{
                align-self: flex-end;
                @include col(5);
                @include col-start(1);
            }

            .image{
                @include col(8);
                @include col-start(9);

                img{
                    width: 100%;
                    height: auto;
                    border-radius: 20px;
                }
            }

            @media only screen and (max-width: $sm-max) {
                .content {
                    @include col(7);
                }
            }

            @media only screen and (max-width: $xs-max) {
                .content {
                    order:2;
                    @include col(16);

                    h5{
                        margin-bottom: 30px;
                    }
                }

                .image{
                    @include col(16);
                    @include col-start(1);
                    margin-bottom: 30px;

                    img{
                        border-radius: 6px;
                    }
                }
            }
        }
    }
}
