//
// COLORS
// Use http://www.color-blindness.com/color-name-hue/ for color naming
// -----------------------------------------------------------------------------

//$color: #color;
$black: #000;
$white: #fff;

$green: #168958;

$red: #FF5046;
$pink: #F4C9D1;
$purple: #B5A9FF;
$blue: #BFE3E8;
$orange: #ff9b50;
$yellow: #F4C746;
$gray: #eaeaea;

//
// MAP-GET() ARRAYS
// -----------------------------------------------------------------------------

//$colors-section-example: (
//    text: $color,
//    border: $color
//);

$colors-listing-projects:(
  border: $black
);

$colors-listing-team:(
  border: $black
)
