.modal-container {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.2);
    z-index: 1000;
    transition: opacity 1s ease-in-out;

    &.-show {
        opacity: 1;

        .modal {
            top: 50%;
        }
    }

    &.-hide {
        opacity: 0;

        .modal {
            top: 45%;
        }
    }
}

.modal {
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    width: 500px;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
    transition:  top .7s ease-in-out;
}

.modal-header {
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 30px;
        line-height: 1;
        background: transparent;
        border: 0;
        cursor: pointer;
    }
}

.modal-footer {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}