@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component {
    &-featured-articles {
        &.articles-count-2{
            .articles{
                .article {
                    @include col(8);

                    @media only screen and (max-width: 640px) {
                        @include col-start(1);
                        @include col(16);
                    }

                    .title-container {
                        display: inline-block;
                        max-width: 90%;
                    }
                }
            }

        }
        &.articles-count-3{
            .articles{
                .article:not(:first-child) {
                    @include col(4);

                    @media only screen and (max-width: 640px) {
                        @include col-start(1);
                        @include col(16);
                    }

                    .image-wrapper{
                        aspect-ratio: 133 / 100;
                    }

                    .article-title{
                        @media only screen and (min-width: 641px) {
                            font-size: vw(20px, false, 12px, 20px);
                        }
                    }

                }

                .article:first-child {
                    @include col(8);

                    @media only screen and (max-width: 640px) {
                        @include col-start(1);
                        @include col(16);
                    }

                    .title-container {
                        display: inline-block;
                        max-width: 90%;
                    }
                }
            }
        }
        &.articles-count-4{
            .articles{
                .article {
                    @include col(4);

                    @media only screen and (max-width: 640px) {
                        @include col-start(1);
                        @include col(16);
                    }

                    .image-wrapper{
                        aspect-ratio: 133 / 100;
                    }

                    .article-title{
                        @media only screen and (min-width: 641px) {
                            font-size: vw(20px, false, 12px, 20px);
                        }
                    }
                }
            }

        }

        .wrapper {
            @include grid(16, var(--gutter-width));

            .title {
                position: relative;
                z-index: 2;
                @include col(10);
                @include col-start(5);
            }

            .suptitle {
                position: relative;
                z-index: 2;
                margin-top: 15px;
                @include col(4);
                @include col-start(1);
            }

            .articles{
                @include col(16);
                @include grid(16, var(--gutter-width));

                .article {
                    .image-wrapper{
                        width: 100%;
                        aspect-ratio: 133 / 100;
                        border-radius: 15px;
                        margin-bottom: 20px;
                        overflow:hidden;
                        display: block;

                        img {
                            position:relative;
                            width: 100%;
                            height: auto;
                            object-fit: cover;
                            transition: all 0.3s ease 0s;

                            &:hover{
                                scale: 1.05;
                            }
                        }
                    }

                    .tags{
                        margin-top: 27px;

                        .tag{
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }

        .buttons {
            display: flex;
            justify-content: flex-end;
            margin-top: 100px;
        }

        @media only screen and (max-width: $sm-max) {
            .wrapper {
                .title {
                    @include col(16);
                    @include col-start(1);
                }
            }
        }

        @media only screen and (max-width: 640px) {
            .wrapper{
                .articles{
                    .article{
                        @include col-start(1);
                        @include col(16);
                        margin-bottom: 70px;

                        &:last-child{
                            margin-bottom: 0;
                        }

                        .image-wrapper{
                            img{
                                transform: none!important;
                            }
                        }
                    }
                }
            }
        }
    }
}
