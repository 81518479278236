@use '../../config' as *;
@use '../../mixins' as *;

.primary-btn {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    min-height: 50px;
    padding: 10px vw(20px, false, 10px);
    align-items: center;
    gap: 0px;
    flex-shrink: 0;
    border: 1px solid $black;
    border-radius: 100px;
    font-size: 20px;
    line-height: 140%;
    color: $black;
    background: $white;
    cursor: pointer;
    transition: all 0.3s ease;

    &.alt {
        border-radius: 12px;
    }

    &.for-header {
        border-color: transparent;
        background: transparent;
        padding: 0 14px;
        line-height: normal;

        /* No hover/focus button state in mobile for the mobile menu */
        @media screen and (max-width: $xs-max) {
            &:hover,
            &:focus {
                border-color: transparent;

                &.with-icon {
                    padding-left: 20px;
                }

                span[class^='icon-'] {
                    display: none;
                }

                &::before {
                    display: none;
                }
            }
        }

        @media screen and (min-width: $sm-min) {
            &:hover,
            &:focus {
                border-color: $black;

                &.with-icon {
                    padding-left: vw(35px, false, 35px);
                }

                span[class^='icon-'] {
                    opacity: 1;
                    left: 14px;
                }
            }
        }

        span.icon-arrow, span.icon-external {
            width: vw(12px, false, 12px);
        }
    }

    &.no-bg {
        background: transparent;

        &:hover,
        &:focus {
            background: $white;
        }
    }

    &:hover,
    &:focus {
        gap: 15px;
        text-decoration: none;

        &.with-icon {
            padding-left: vw(40px, false, 40px);

            &.is-external{
                padding-left: vw(37px, false, 37px);
            }
        }

        &:before {
            top: 0;
        }

        span[class^='icon-'] {
            opacity: 1;
            left: 14px;
        }
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 100%;
        z-index: 0;
        -webkit-transition: top 0.09s ease-in;
    }

    span[class^='icon-'] {
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translate(-0%, -50%);
        opacity: 0;
        transition: opacity 0.15s ease, left 0.15s ease;
    }

    span {
        position: relative;
        z-index: 1;
    }
}

@media only screen and (max-width: $sm-max) {
    .primary-btn {
        min-height: 45px;
        padding: 8px 20px;
    }
}

@media only screen and (max-width: $xs-max) {
    .primary-btn {
        font-size: 18px;
    }
}
