@use '../../config' as *;
@use '../../mixins' as *;
@use '../../functions' as *;

.contact-footer{
    @include grid(16, var(--gutter-width));

    position: relative;
    background: $white;
    border: 1px solid $black;
    border-radius: 20px;
    padding: vw(100px, false, 30px) vw(60px, false, 30px);
    margin-bottom: 150px;
    transition: all 0.6s ease;

    &.career{
        .icon-animated-contact-footer{
            top: 16%;
        }

        &:hover{
            background: $purple;
            border: 1px solid $purple;
        }
    }

    &.coffee {
        .icon-animated-contact-footer{
            top: 35%;
        }

        &:hover{
            background: $pink;
            border: 1px solid $pink;
        }
    }

    .icon-animated-contact-footer{
        position: absolute;
        top: 33%;
        right: 5%;
        transform: translateY(-50%);

        #quatrecentquatre-website-parlons-nous-fr, #quatrecentquatre-website-parlons-nous-en {
            #quatrecentquatre-website-parlons-nous-fr-u-mouth, #quatrecentquatre-website-parlons-nous-en-u-mouth {
                translate: -6px;

            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        padding: 60px 16px;

        &.career{

            br{
                display: none;
            }

            .icon-animated-contact-footer{
                display: none;
            }
        }

        .icon-animated-contact-footer{
            display: none;
        }
        span.like-h2{
            max-width: unset;
        }
    }

    &:hover,
    &:focus{
        text-decoration: none;
        background: $blue;
        border: 1px solid $blue;
    }

    .suptitle {
        @include col-start(1);
        @include col(10);

        font-size: vw(30px, false, 20px);
        line-height: 110%;
        margin-bottom: 30px;

        @media only screen and (max-width: $xs-max) {
            @include col(16);
            margin-bottom: 20px;
        }
    }

    span.like-h2{
        margin-left: -5px;
        max-width: 75%;
    }
}
