@use '../config' as *;
@use '../mixins' as *;
@use '../functions' as *;

.projects-hero {
    svg.illustration,
    .illustration {
        &.illustration-star-right {
            @media screen and (max-width: $sm-max) {
                display: none;
            }
        }
    }
}
