@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.project-prefooter {
    margin-top: 40px;

    @media screen and (max-width: $xs-max) {
        margin-top: 50px;
    }

    progress {
        /* Reset the default appearance */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        /* Get rid of default border in Firefox. */
        border: none;

        height: 3px;
        width: 100%;
        border-top: 1px solid $black;

        &::-webkit-progress-bar {
            background-color: $white;
        }

        &::-moz-progress-bar {
            background-color: $black;
        }

        &::-webkit-progress-value {
            background-color: $black;
            transition: all 0.1s ease;
        }
    }

    .progress-percent {
        margin-top: 10px;
        font-size: vw(14);
        line-height: 1.5;

        @media screen and (max-width: $xs-max) {
            font-size: 14px;
        }
    }

    .next-project {
        position: relative;
        margin-top: 40px;
        @include grid(16, var(--gutter-width));

        @media screen and (max-width: $xs-max) {
            margin-top: 50px;
        }

        .suptitle {
            @include col(4);

            @media screen and (max-width: $xs-max) {
                font-size: 14px;
                position: absolute;
                top: -40px;
                left: 0;
                text-align: right;
            }
        }

        &-info {
            @include col(8);
            @include col-start(5);

            @media screen and (max-width: $xs-max) {
                @include col(16);
                @include col-start(1);
            }
        }

        .arrow-cta {
            @include col(2);
            @include col-start(15);
            text-align: right;
            width: 50px;
            height: 50px;
            margin-left: auto;

            @media screen and (max-width: $xs-max) {
                display: none;
            }
        }
    }
}
