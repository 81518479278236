@use '../../config' as *;
@use '../../mixins' as *;
@use '../../functions' as *;

.input-wrapper {
    position: relative;
    margin-bottom: 20px;
    width: 100%;

    label {
        position: absolute;
        left: 20px;
        top: 30px;
        transform: translateY(-50%);
        font-size: vw(20px, false, 16px, 22px);
        line-height: 140%;
        @include steradian();
    }

    .error-msg {
        margin: 15px 0 0 0;
        font-size: vw(13px, false, 11px, 15px);
        line-height: calc(14 / 13);
        @include steradian();
        color: $red;
        letter-spacing: 0;
    }

    button.submit {
        position: absolute;
        right: 8px;
        top: 6px;

        padding: 14px 24px;
        font-size: vw(16px, false, 14px, 18px);
        line-height: calc(18 / 16);
    }
}

textarea {
    @include steradian();
    font-size: vw(20px, false, 16px, 22px);
    line-height: 150%;
    display: block;
    padding: 20px;
    border: 1px solid $black;
    background: transparent;
    min-height: 150px;
    width: 100%;
    transition: border 0.25s ease;
    border-radius: 12px;
    resize: none;

    &:focus,
    &.has-value {
        padding-bottom: 0;

        & + label {
            padding: 0 0 28px 0;
            font-size: vw(14px, false, 12px, 14px);
        }
    }

    & + label {
        transition: all 0.3s $ease-in-out-quad;
        padding: 0;
        font-size: vw(20px, false, 16px, 22px);
        line-height: 140%;
    }

    &.has-error {
        border: 1px solid $red;

        & + label {
            color: $red;

            & + .error-msg {
                color: $red;
            }
        }
    }
}

.checkbox-wrapper,
.radio-wrapper {
    &.tag-style {
        display: inline-flex;
        cursor: pointer;

        .tag-style {
            margin-bottom: 0;
        }

        input {
            position: absolute;
            z-index: -1;

            &:checked {
                ~ .checkmark,
                ~ .radio-btn {
                    &::before {
                        top: 0;
                    }
                }
            }
        }
    }

    .tag-style {
        @extend .tag;
    }
}

input[type='date'] {
    color: transparent;
    cursor: pointer;
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    &.has-value {
        transition: all ease 250ms;
        color: $black;
        & + label {
            padding: 20px 30px 10px 30px;
        }
    }
    & + label {
        cursor: pointer;
    }
}

input[type='text'],
input[type='email'],
input[type='phone'],
input[type='password'],
input[type='date'] {
    @include steradian();
    font-size: vw(20px, false, 16px, 22px);
    line-height: 1.4;
    padding: 12px 20px;
    border-radius: 12px;
    border: 1px solid $black;
    background: transparent;
    width: 100%;
    transition: border 0.25s ease;
    min-height: 60px;
    box-sizing: border-box;

    &.has-value {
        padding-bottom: 0;

        & + label {
            padding: 0 0 28px 0;
            font-size: vw(14px, false, 12px, 14px);
            line-height: 140%;
        }
    }

    &.has-error {
        border: 1px solid $red;

        & + label {
            color: $red;

            & + .error-msg {
                color: $red;
            }
        }
    }

    & + label {
        transition: all 0.3s $ease-in-out-quad;
        padding: 0;
        font-size: vw(20px, false, 16px, 22px);
    }
}

.file-input {
    &.has-error {
        label,
        .file-name {
            border-color: $red;

            svg {
                * {
                    stroke: $red;
                }
            }
            &:not(.button) {
                color: $red;
            }
        }

        .error-msg {
            color: $red;
            display: block;
        }
    }

    .file-upload {
        display: flex;
        align-items: center;

        &:hover,
        &:focus {
            .icon-upload path:nth-child(2),
            .icon-upload path:nth-child(3) {
                transform: translateY(-3px);
            }
        }

        input[type='file'] {
            &::file-selector-button {
                display: none;
            }
        }

        input[type='file' i] {
            display: none;
        }

        .file-text {
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 20px;
            min-width: 0;

            @media only screen and (max-width: $xs-max) {
                margin-left: 0;
            }
        }

        label,
        .file-name {
            position: relative;
            left: unset;
            top: unset;
            transform: none;
            white-space: nowrap;

            &.button {
                margin-right: 20px;
            }
        }

        .file-name {
            font-size: vw(14px, false, 12px, 14px);
            line-height: 1.5;
            @include steradian();
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .icon-upload {
            width: 21px;

            svg {
                overflow: visible;
            }

            path {
                transition: transform 0.2s ease-out;
            }
        }

        button {
            &.remove {
                position: relative;
                height: 100%;
                padding: 10px;
                margin-left: 2px;
                border: none;
                background: none;
                color: $black;
                cursor: pointer;
                z-index: 1;

                &:before,
                &:after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    content: '';
                    height: 13px;
                    width: 1px;
                    background-color: $black;
                }

                &:before {
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &:after {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }
    }

    .file-input-label {
        cursor: pointer;

        &.button {
            top: unset;
            left: unset;
            transform: none;
            position: relative;
            min-width: 210px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s $ease-in-out-quad;

            &:hover {
                background-color: $white;
            }
        }
    }
}

@media only screen and (max-width: $xs-max) {
    .input-wrapper {
        position: relative;
        margin-bottom: 20px;

        label {
            font-size: vw(18px, true, 16px, 18px);
        }

        input[type='text'],
        input[type='email'],
        input[type='phone'],
        input[type='password'],
        input[type='date'] {
            font-size: vw(18px, true, 16px, 18px);
        }

        textarea.has-value {
            & + label {
                font-size: vw(14px, true, 12px, 14px);
            }
        }

        .file-upload {
            flex-direction: column;
            row-gap: 10px;
            align-items: flex-start;

            .file-text {
                max-width: 100%;
            }
        }
        .file-input-label {
            &.button {
                width: 100%;
                left: unset;
            }
        }
    }
}
