@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.pagebuilder-component {
    &-cool-text {
        .wrapper {
            @include grid(16, var(--gutter-width));

            position: relative;

            .icon {
                position: absolute;
                top: -100px;
                right: 5%;
            }

            .text {
                p {
                    @include freigeist-light();
                    font-size: vw(75px, false, 40px);
                    line-height: 115%;
                    letter-spacing: 0.75px;

                    & + p {
                        margin-top: 70px;
                    }

                    div {
                        opacity: 0;
                        transform: translateY(100px);
                    }
                }

                a {
                    color: $red;

                    &.no-underline{
                        cursor: auto;
                    }

                    span {
                        display: inline-block;
                        font-family: inherit;
                        font-weight: inherit;
                    }

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }
                }
            }

            .cta {
                margin-top: 40px;
            }

            .signature-section {
                @include col(4);
                @include col-start(13);

                .signature-highlight {
                    font-weight: bold;
                    margin: 50px 0 10px;
                }

                .signature-name {
                    margin-top: 0;
                }
            }
        }

        .thumbnail {
            position: fixed;
            top: 0;
            left: 0;
            width: 400px;
            height: 424px;
            background-color: $gray;
            border-radius: 6px;
            overflow: hidden;
            pointer-events: none;
            opacity: 0;

            video,
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        @media only screen and (max-width: $xs-max) {
            .wrapper {
                .icon {
                    display: none;
                }

                .text {
                    p {
                        line-height: 110%;
                        letter-spacing: 0.4px;

                        & + p {
                            margin-top: 30px;
                        }
                    }
                }

                .cta {
                    margin-top: 30px;
                }

                .signature-section {
                    @include col(16);
                    @include col-start(1);
                }
            }
        }
    }
}
