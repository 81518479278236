@use '../config' as *;
@use '../mixins' as *;
@use '../functions' as *;

.services-wrapper {
    .navigation {
        margin-bottom: 200px;
        display: flex;
        @include col(12);
        @include col-start(5);
        gap: 125px;

        @media screen and (max-width: $xs-max) {
            @include col(16);
            @include col-start(1);
            flex-direction: column;
            gap: 50px;
        }

        ul {
            margin: 30px 0 0 0;
            padding: 0;

            li {
                list-style-type: none;

                & + li {
                    margin-top: 20px;
                }
            }
        }
    }

    .solutions-title {
        @include col(12);
        @include col-start(5);
        margin-bottom: 170px;

        @media screen and (max-width: $xs-max) {
            @include col(16);
            @include col-start(1);
            margin-bottom: 80px;
        }
    }

    & + .pagebuilder-component-projects_carousel {
        margin-top: 0;
    }
}
