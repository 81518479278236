@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component {
    &-text {
        .title {
            @include col(16);
            @include col-start(1);
        }

        .wrapper{
            >*:last-child{
                margin-bottom:0;
            }
        }

        @media only screen and (max-width: $sm-max) {

            .title{
                @include col-start(1);
                @include col(16);
            }
        }
    }
}
