// @font-face definitions

// Example
// @font-face{
//    font-family:"Trade Gothic LT W05 Roman";
//    src:url("../fonts/d4a722a6-2380-42f5-9390-886fdba5ba81.woff2") format("woff2"),url("../fonts/580bd5b6-412a-4178-b3eb-4e2046897cbf.woff") format("woff");
//}

@font-face {
    font-family: "Steradian-Rg";
    src: url("../fonts/Steradian-Rg.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "FreigeistCon-Light";
    src: url("../fonts/FreigeistCon-Light.otf") format("opentype");
    font-display: swap;
}
