@use 'sass:map';
@use '../../config' as *;
@use '../../mixins' as *;
@use '../../functions' as *;

.base-form {
    @include grid(16, var(--gutter-width));

    height: auto;
    margin-bottom: 60px;
    padding: 0;

    &.filled {
        padding: vw(150px, false, 150px) 0;
    }

    .contact-information {
        @include col(3);

        margin-top: 10px;

        p {
            font-size: vw(14px, false, 12px);
        }

        @media only screen and (max-width: $sm-max) {
            @include col(4);
            @include col-start(1);
        }

        @media only screen and (max-width: $xs-max) {
            @include col(16);

            margin-top: 0;

            p {
                font-size: vw(14px, true, 12px, 16px);
            }
        }
    }

    .contact-form {
        @include col(12);
        @include col-start(5);
        @include grid(12, var(--gutter-width));

        grid-row-start: 1;
        margin-bottom: 55px;

        @media only screen and (max-width: $xs-max) {
            @include col(16);
            @include col-start(1);
            @include grid(16, var(--gutter-width));
        }

        .title,
        .description {
            @include col(10);

            margin-bottom: 42px;

            @media only screen and (max-width: $sm-max) {
                @include col(15);
                @include col-start(2);
            }

            @media only screen and (max-width: $xs-max) {
                @include col(16);
                @include col-start(1);

                margin-bottom: 62px;
            }
        }

        form {
            @include grid(12, var(--gutter-width));

            opacity: 0;
            transform: translateY(30px);

            @media only screen and (max-width: $xs-max) {
                @include col(16);
                @include grid(16, var(--gutter-width));
            }

            fieldset {
                @include col(9);

                min-width: 0;

                @media only screen and (max-width: $sm-max) {
                    @include col(11);
                    @include col-start(2);
                }

                @media only screen and (max-width: $xs-max) {
                    @include col(16);
                    @include col-start(1);
                }
            }

            .recaptcha {
                font-size: smaller;
                margin: 40px 0 0;

                @media only screen and (max-width: $xs-max) {
                    margin-top: 20px;
                }
            }

            .form-actions {
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;

                @media only screen and (max-width: $xs-max) {
                    margin-top: 40px;
                }

                .primary-btn {
                    &:hover,
                    &:focus {
                        &.with-icon {
                            padding-left: vw(40px, false, 40px);

                            .icon-arrow{
                                left:10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
