@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.projects-listing {
    .filters {
        margin-bottom: 35px;

        span.filter-by {
            margin-right: 10px;
        }

        > * {
            margin-bottom: 5px;
        }
    }

    .projects-list-wrapper {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        border-top: 1px solid $black;

        .project-list-wrapper:hover {
            text-decoration: none;
        }

        .project-wrapper {
            @include grid(16, var(--gutter-width));
            padding: 35px 0 40px;
            align-items: center;
            height: auto;
            min-height: 140px;

            @media screen and (max-width: $xs-max) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }

        .p-title {
            @include col(7);
            margin: 0;
        }

        .p-type {
            @include col(4);
            @include col-start(9);
            margin: 0;
        }

        .p-cta {
            @include col(4);
            @include col-start(13);
            text-align: right;

            .arrow-cta {
                margin-left: auto;
                width: 65px;
                height: 65px;
            }
        }

        @media screen and (max-width: $xs-max) {
            grid-auto-rows: auto;

            .p-title {
                margin-bottom: 23px;
            }

            .p-type {
                margin-bottom: 0;
            }

            .p-cta {
                display: none;
            }
        }
    }

    .articles-list-wrapper {
        @include grid(16, var(--gutter-width));

        .articles{
            @include col(16);
            @include grid(16, var(--gutter-width));

            row-gap: rem(50px);

            .article {
                @include col(4);

                @media only screen and (max-width: 640px) {
                    @include col-start(1);
                    @include col(16);
                }

                .article-title{
                    @media only screen and (min-width: 641px) {
                        font-size: vw(20px, false, 12px, 20px);
                    }
                }

                &:nth-child(14n + 1), &:nth-child(14n + 10) {
                    @include col(8);

                    @media only screen and (max-width: 640px) {
                        @include col-start(1);
                        @include col(16);
                    }
                    
                    .title-container {
                        display: inline-block;
                        max-width: 90%;

                        .article-title {
                            font-size: vw(32px, false, 20px);
                        }
                    }
                }

                .image-wrapper {
                    width: 100%;
                    aspect-ratio: 133 / 100;
                    border-radius: 15px;
                    margin-bottom: 20px;
                    overflow:hidden;
                    display: block;

                    img{
                        position:relative;
                        height: auto;
                        width: 100%;
                        object-fit: cover;
                        transition: all 0.3s ease 0s;

                        &:hover{
                            scale: 1.05;
                        }
                    }
                }

                .tags{
                    margin-top: 27px;

                    .tag{
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }

    .thumbnail {
        position: fixed;
        top: 0;
        left: 0;
        width: 350px;
        height: 400px;
        border-radius: 6px;
        overflow: hidden;
        pointer-events: none;
        opacity: 0;
        z-index: 2;
        transition: opacity 0.3s ease;

        @media screen and (max-width: $sm-max) {
            display: none;
        }

        &.active {
            opacity: 1;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.3s ease;

            &.active {
                opacity: 1;
            }
        }
    }
}
