@use '../../config' as *;
@use '../../mixins' as *;

.tag {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    height: 50px;
    padding: 12px 20px;
    align-items: center;
    gap: 0px;
    flex-shrink: 0;
    white-space: nowrap;
    border: 1px solid $black;
    border-radius: 100px;
    font-size: rem(20);
    color: $black;
    background: $white;
    transition: all 0.3s ease;
    text-decoration: none;

    &.alt {
        border-radius: 6px;
    }

    &.small {
        height: 35px;
        padding: 12px 15px;
        font-size: rem(14);
    }

    &:hover, &:active {
        text-decoration: none;

        &:before {
            top: 0;
        }
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 100%;
        z-index: 0;
        -webkit-transition: top 0.09s ease-in;
    }

    span {
        position: relative;
        z-index: 1;
    }
}

@media only screen and (max-width: $sm-max) {
    .tag {
        height: 35px;
        padding: 12px 15px;
    }
}
