@use "../../config" as *;
@use "../../mixins" as *;
@import "../../functions";

footer {
    margin: vw(150px) 0 40px;
    background: $white;

    &.footer-small {
        margin-top: vw(50px);
    }

    .columns{
        display:flex;
        gap: 12px;
        justify-content:flex-end;
        margin-bottom: 50px;

        .col{
            &:nth-child(1){
                display:flex;
                gap: 20px;
                width: calc((480 / 1360) * 100%);

                ul{
                    display:none;
                }
            }
            &:nth-child(2){
                display:flex;
                gap: 3px;
                width: calc((382 / 1360) * 100%);
            }
            &:nth-child(3){
                display: flex;
                justify-content: end;
                width: calc((480 / 1360) * 100%);

                sup{
                    line-height:0;
                }

                .texts{
                    max-width: 275px;
                    box-sizing: border-box;

                    button {
                        margin: 20px 0;
                    }

                    a, button {
                        font-size: rem(20px);
                    }

                    p {
                        margin-top: 0;
                        &:not(.suptitle) {
                            font-size: rem(20px);
                        }
                    }
                }

                p{
                    margin: 10px 0;

                    &:first-child{
                        margin-top:0;
                    }

                    &:last-child{
                        margin-bottom:0;
                    }
                }

                button.copy-link{
                    font-size: rem(20);
                    line-height: 1;

                    @media only screen and (max-width: $sm-max) {
                            .icon-copy, .icon-checkmark{
                                display:none;
                            }
                        }
                }

                .phone{
                    display: inline-flex;
                    line-height: 1;
                }
            }

            ul{
                list-style: none;
                padding-left:0;
                padding-right: 20px;
                margin:0;
                width: calc(50% - 10px);

                &:last-child{
                    padding-right: 0;
                }

                li{
                    margin: 15px 0;

                    a{
                        font-size: rem(20px);
                    }

                    &:first-child{
                        margin-top:0;
                    }
                    &:last-child{
                        margin-bottom:0;
                    }
                }
            }
        }
    }
    .scaling-logo{
        display: grid;
        gap: 12px;
        position: relative;
        min-height: 285px;

        div{
            position: absolute;
            bottom: 0;
            box-sizing: content-box;
            border:1px solid black;
            display:flex;
            align-items: flex-end;
            justify-content: center;
            transform-origin: bottom;

            > span:last-child{
                display:none;
            }

            >span{
                margin-bottom: calc(var(--logo-text-margin-bottom) * 1px);
            }
        }

        div:nth-child(1), div:nth-child(3){
            border-radius: calc(var(--logo-border-radius) * 1px);
            width: calc((480 / 1360) * 100%);
            aspect-ratio: 480/210;

            .icon-logo-quatre{
                width: calc(344/480 * 100%);
            }
        }

        div:nth-child(2){
            border-radius: 40px;
            width: calc((382 / 1360) * 100%);
            aspect-ratio: 382/210;
            justify-self: center;

            .icon-logo-cent{
                width: calc(263/404 * 100%);
            }
        }

        div:nth-child(3){
            justify-self: flex-end;
        }

        .icon-logo-quatre,
        .icon-logo-cent {
            max-width: 100%;
        }
    }

    .extra-infos{
        display:flex;
        gap: 30px;
        list-style: none;
        padding:0;
        margin:40px 0 0;

        button{
            text-decoration: none;
            padding: 0;
            background-image: linear-gradient(currentColor, currentColor);
            background-position: 0% 100%;
            background-repeat: no-repeat;
            background-size: 0% 1px;
            transition: background-size 0.2s ease;
    
            &:hover, &:focus {
                text-decoration: none;
                background-size: 100% 1px;
            }
        }

        li{
            display:flex;

            &.language-switcher{
                margin-left:auto;
            }
        }
    }

    @media only screen and (max-width: $sm-max) {
        .columns{
            .col{
                ul{
                    width: 100%;
                }
                &:nth-child(1){
                    ul{
                        display:block;
                    }
                }
                &:nth-child(2){
                    ul:first-child{
                        display:none;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $xs-max) {
        margin: 100px 0 20px;

        &.footer-small {
            margin-top: 50px;
        }

        .scaling-logo {
            gap: 6px;
            min-height: 110px;

            div {
                span:first-child {
                    display: none;
                }
                span:last-child {
                    display: block;
                }

                >span{
                    margin-bottom: calc(var(--logo-text-margin-bottom-mobile) * 1px);
                }
            }

            div:nth-child(1), div:nth-child(3){
                border-radius: 130px;
                width: calc((110 / 343) * 100%);
                aspect-ratio: 1/1;

                span{
                    width: calc((30 / 110) * 100%);
                }
            }

            div:nth-child(2){
                border-radius: 15px;
                width: calc((110 / 343) * 100%);
                aspect-ratio: 1/1;
                justify-self: center;

                span{
                    width: calc((41 / 110) * 100%);
                }
            }
        }

        .columns{
            flex-wrap: wrap;
            gap:0;
            margin-bottom: 75px;

            .col{
                &:nth-child(1){
                    width: 50%;
                }
                &:nth-child(2){
                    width: 50%;
                }
                &:nth-child(3){
                    flex-basis: 100%;
                    margin-top: 40px;
                    justify-content: start;

                    .texts{
                        max-width: 100%;
                        padding-left: 0%;
                    }
                }

                ul{
                    width: 100%;
                }
            }
        }

        .extra-infos{
            position: relative;
            flex-wrap: wrap;
            width: 100%;
            gap:20px;

            li.language-switcher{
                width: 80%;
                &:last-child{
                    position: absolute;
                    top:0;
                    right:0;
                    width: auto;
                }

                p{
                    margin:0;
                }
            }
        }
    }
}
