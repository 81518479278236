@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.pagebuilder-component {
    &-listing {
        padding: 200px 0;
        overflow: hidden;

        .wrapper {
            @include grid(16, var(--gutter-width));

            .title {
                position: relative;
                z-index: 2;
                @include col(10);
                @include col-start(5);
            }

            .suptitle {
                position: relative;
                z-index: 2;
                margin-top: 15px;
                @include col(4);
                @include col-start(1);
            }
        }

        .listing-section {
            position: relative;
            display: flex;
            flex-direction: column;

            .icon {
                position: absolute;
                top: 0;
                left: 11%;
                transform: translateY(-50%);
                max-height: 120px;
                z-index: 1;
            }

            & > div {
                position: relative;
                @include grid(16, var(--gutter-width));
                padding: 50px 0;

                &:last-child{
                    padding-bottom:0;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -15vw;
                    width: 150vw;
                    height: 1px;
                    background-color: $black;
                }

                .index {
                    @include col(4);
                    @include col-start(1);
                    padding-top: 3px;
                }

                .content {
                    @include col(7);
                    @include col-start(5);

                    .title {
                        margin-bottom: 35px;
                    }

                    a {
                        margin-top: 15px;
                    }
                }

                .list {
                    @include col(4);
                    @include col-start(13);

                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                        font-size: rem(14px);

                        li + li {
                            margin-top: 18px;
                        }
                    }
                }
            }
        }

        .cta {
            position: relative;
            bottom: -50px;
            right: 0;
            text-align: right;
        }

        @media only screen and (max-width: $md-max) {
            .wrapper {
                .title {
                    @include col(11);
                }
            }
        }

        @media only screen and (max-width: $xs-max) {
            padding: 100px 0 100px;

            .wrapper {
                display: flex;
                flex-direction: column;

                .suptitle {
                    margin-bottom: 30px;
                }

                .title {
                    margin-bottom: 70px;
                }
            }

            .listing-section {
                .icon {
                    display: none;
                }

                & > div {
                    position: relative;
                    padding: 50px 0 40px;

                    .index {
                        @include col(1);
                        position: absolute;
                        top: 50px;
                    }

                    .content {
                        @include col(14);
                        @include col-start(3);

                        .title {
                            margin-bottom: 20px;
                        }

                        p {
                            font-size: rem(18);
                        }

                        .tertiary-btn{
                            padding: 0;
                        }
                    }

                    .list {
                        @include col(14);
                        @include col-start(3);
                        margin-top: 40px;
                    }
                }
            }

            .cta {
                bottom: -30px;
            }
        }
    }
}
