@use '../../config' as *;
@use '../../mixins' as *;
@use '../../functions' as *;

.section-header{
  @include grid(16, var(--gutter-width));

  .suptitle{
    @include col(4);
    margin-top: 15px;

    &:last-child{
      margin-bottom: 20px;
    }
  }

  .information-right{
    @include col(10);
    margin-bottom: 100px;

    .title{
      margin-bottom: 25px;
    }

    p{
      margin-top: 0;
    }
  }

  @media only screen and (max-width: $xs-max) {
    .suptitle, .information-right{
      @include col(16);
      margin-bottom: 70px;
    }

    .suptitle{
      margin-top: 0;
      margin-bottom: 25px;

      &:last-child{
        margin-bottom: 25px;
      }
    }
  }
}
