@use '../../../config' as *;
@use '../../../mixins' as *;
@use '../../../functions' as *;

.pagebuilder-component {
    &-projects {
        overflow: clip;

        --photoHeight: calc(100vh - 150px);
        --photoHeightContainer: calc((100vh - 150px) * 3);


        @media only screen and (max-width: $sm-max) {
            --photoHeight: calc(90vh - 150px);
            --photoHeightContainer: calc((90vh - 150px) * 3);
        }

        @media only screen and (max-width: $sm-min) and (min-width: $xs-max) {
            --photoHeight: calc(65vh - 150px);
            --photoHeightContainer: calc((65vh - 150px) * 3);
        }

        .wrapper {
            @include grid(16, var(--gutter-width));

            .title {
                position: relative;
                z-index: 2;
                @include col(10);
                @include col-start(5);
            }

            .suptitle {
                position: relative;
                z-index: 2;
                margin-top: 15px;
                @include col(4);
                @include col-start(1);
            }
        }

        .scrolling-section {
            position: relative;
            @include grid(16, var(--gutter-width));
            height: var(--photoHeightContainer);
            transition: opacity 0.3s ease;

            &.in-transition {
                opacity: 0;
            }
        }

        .projects-section {
            flex-direction: column;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;

            .project {
                display:flex;
                position: relative;
                align-items: flex-end;
                height: var(--photoHeight);
                //@NOTE Padding added to force spacing between project if text too long.
                padding-top: 30px;

                .image-placeholder{
                    height: 100%;
                    flex: 0 0 79vh;

                    @media only screen and (max-width: $xs-max) {
                        display: none;
                    }

                }

                .infos {
                    position: relative;
                    z-index: 1;
                    max-width: 30%;

                    @media only screen and (max-width: $xs-max) {
                        max-width: 100%;
                    }

                    img {
                        display: none;
                    }

                    .title {
                        margin-bottom: 40px;
                    }

                    .description {
                        *:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .images-section {
            position: sticky;
            top: 115px;
            margin-left:auto;
            width: 71vh;
            max-width: 65vw;
            aspect-ratio: 655/731;

            height: var(--photoHeight);

            @media only screen and (max-width: $sm-max) and (min-width: $xs-max) {
                max-width: 58vw;
            }

            .images {
                position: sticky;
                width: 100%;
                height: var(--photoHeight);
                overflow: hidden;
                border-radius: 20px;
            }

            a {
                position: absolute;
                height: var(--photoHeight);
                min-width: 100%;

                img {
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        z-index: #{10 - $i};
                    }
                }
            }
        }

        .count {
            position: absolute;
            bottom: -3px;
            right: calc(100% + var(--gutter-width));
            width: 90px;
            text-align: right;
            font-size: 16px;
        }

        .buttons {
            display: flex;
            justify-content: flex-end;
            margin-top: 100px;
        }

        @media only screen and (max-width: $md-max) {
            .wrapper {
                .title {
                    @include col(11);
                }
            }
            .projects-section {
                .project {
                    align-items: flex-end;
                }
            }
        }

        @media only screen and (max-width: $sm-max) {
            .projects-section {
                .project {
                    padding:0;
                    align-items: flex-end;

                    .image-placeholder{
                        flex: 0 0 65vw;
                    }
                }
            }
        }

        @media only screen and (max-width: $xs-max) {
            .wrapper {
                display: flex;
                flex-direction: column;

                .suptitle {
                    margin-bottom: 30px;
                }

                .title {
                    margin-bottom: 70px;
                }
            }

            .scrolling-section {
                height: auto;
            }

            .projects-section {
                width: 100%;
                position: relative;
                @include grid(12, var(--gutter-width));
                row-gap: 70px;

                .project {
                    padding: 0;
                    height: auto;

                    .infos {
                        .title {
                            margin-bottom: 15px;
                        }

                        .count {
                            display: none;
                        }

                        img {
                            border-radius: 6px;
                            display: block;
                            margin-bottom: 30px;
                            object-fit: cover;
                        }
                    }
                }
            }

            .images-section {
                display: none;
            }

            .buttons {
                justify-content: flex-start;
            }
        }
    }
}
