@use "../../../config" as *;
@use "../../../mixins" as *;
@use "../../../functions" as *;

.pagebuilder-component {
    &-credits {
        border-top: 1px solid $black;
        padding-top: 50px;

        .wrapper {
            @include grid(16, var(--gutter-width));

            .title {
                @include col(4);

                text-transform: uppercase;
            }

            .credits {
                @include col(13);
                @include col-start(5);

                .wrapper {
                    @include grid(12, var(--gutter-width));

                    row-gap: 30px;

                    .credit-item {
                        @include col(4);

                        label, p {
                            font-size: rem(14px);
                            line-height: 1.5;
                        }

                        label {
                            font-weight: bold;
                        }

                        p {
                            margin-top: 0;
                        }
                    }

                }
            }
        
        }

        @media only screen and (max-width: $md-max) {
            .wrapper {
                .credits .wrapper {
                    .credit-item {
                        @include col(6);
                    }
                }
            }
        }

        @media only screen and (max-width: $sm-max) {
            .wrapper {
                h2, .credits {
                    @include col(16);
                    @include col-start(1);
                }

                h2 {
                    margin: rem(50px) 0;
                }
    
                .credits .wrapper {
                    .credit-item {
                        @include col(12);
                        @include col-start(1);
                    }
                }
            }
        }
    }
}
