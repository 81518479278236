@use "../../config" as *;
@use "../../mixins" as *;

.secondary-btn{
    position: relative;
    overflow: hidden;
    display: inline-flex;
    height: 50px;
    padding: 12px 20px;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
    border: 1px solid $black;
    border-radius: 12px;
    font-size: rem(20);
    line-height: 140%;
    color: $black;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 100%;
        z-index: 0;
        -webkit-transition: top 0.09s ease-in;
    }

    span{
        position: relative;
        z-index:1;
    }

    &:hover{
        text-decoration: none;

        &:before {
            top: 0;
        }
    }
}

@media only screen and (max-width: $sm-max) {
    .secondary-btn{
        height: 45px;
        padding: 12px 20px;
        font-size: rem(18);
    }
}
